import Vue from 'vue';
import moment from 'moment';

import 'moment/locale/ru';

Vue.filter('moment', (value, format) => {
	if (value === null || value === undefined || format === undefined) {
		return '';
	}
	if (format === 'from') {
		return moment(value).fromNow();
	}
	return moment(value).format(format);
});

moment.updateLocale('ru', {
	months: [
		'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля',
		'августа', 'сентября', 'октября', 'ноября', 'декабря',
	],
});

moment.locale('ru');

if (typeof window !== 'undefined') {
	window.moment = moment;
	console.warn('moment imported');
}

export default moment;
